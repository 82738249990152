.productWrapper {
    margin: 47px auto 40px;
    max-width: 1440px;
    box-sizing: border-box;
    /*padding: 0 84px 0 120px*/
    padding: 0 120px;
    display: flex;
}

.productWrapper .productImg {
    display: flex;
}

.productDescriptionWrapper {
    max-width: 1440px;
    margin: auto;
    padding: 23px 0 19px 170px;
    position: relative;
}

.productDescriptionWrapper .tab .label {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #696969;
    margin: 23px 60px 24px 0;
}

.productDescriptionWrapper .tab .label.active {
    color: #000000;
}

.productDescriptionWrapper .tab {
    padding: 0 0px 24px 0;
}



.productDescriptionWrapper .brand {
    display: block;
}

.productDescriptionWrapper .brand .logo {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
}


.productDescriptionWrapper .brand .follow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
}

.productDescriptionWrapper .brand .follow span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
}

.productDescriptionWrapper .description {
    display: inline-flex;
}

.productDescriptionWrapper .description span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 203%;
    color: #696969;
}


.productDescriptionWrapper .brand {
    display: block;
}

.productDescriptionWrapper .brand .logo {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 167px;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
}


.productDescriptionWrapper .brand .follow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
}

.productDescriptionWrapper .brand .follow span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #696969;
    margin-left: 9px
}


.panelWrapper {
    position: relative;
}

.panelWrapper a {
    right: 0;
    background: #FAFAFA;
    bottom: 2%
}

.show, .hide {
    position: absolute;
    bottom: -1em;
    z-index: 100;
    text-align: center;
}

.hide {
    display: none;
}
.show:target {
    display: none;
}
.show:target ~ .hide {
    display: block;
}
.show:target ~ .panel {
    max-height: 2000px;
}
.show:target ~ .fade {
    margin-top: 0;
}

.panel {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 1s ease;
}
.fade {
    background: linear-gradient(to bottom, rgba(250, 250, 250,0) 0%,rgba(250, 250, 250,1) 100%);
    height: 100px;
    margin-top: -100px;
    position: relative;
}


.deliveryContent {
    max-width: 437px;
    height: 168px;
    margin: 0 208px 59px 0;
}

.deliveryContent h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 11px;
}

.deliveryContent div {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    margin-bottom: 17px;
}

.button {
    display: block;
    width: 244px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #000000;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: transparent;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

.button:hover {
    background-color: #000000;
    color: #FFFFFF;
    transition: 0.5s;
}
